import { useCallback, useMemo } from 'react';
import tips from '../../../Constants/tips.json';

const TipsComponent = () => {
    const appName = process.env.REACT_APP_NAME;

    const updatedTips = useMemo(() => tips.map(t => t.replace(/{{APP_NAME}}/g, appName)), [appName]);

    const getRandomTip = useCallback(() => {
        const randomIndex = Math.floor(Math.random() * updatedTips.length);
        return updatedTips[randomIndex];
    }, [updatedTips]);

    const randomTip = getRandomTip();

    return (
        <div>
            <p>{randomTip}</p>
        </div>
    );
};

export default TipsComponent;
