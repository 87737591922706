import axios from 'axios';
import { handleResponse, handleError } from './response';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from './common';
import * as urlconstant from '../Constants/urlConstants';

const BASE_URL = urlconstant.BASE_URL;
const cancelTokenMap = {};

// Function to get headers
export const getHeaders = () => {
  return {
    'Accept': 'application/json',
    'Authorization': commonServices.getLocalData(storageConstants.AUTH) !== null ? `Bearer ${commonServices.getLocalData(storageConstants.AUTH)}` : null,
    'Content-Type': 'application/json',
  };
};

/**
 * Cancel previous request if it exists and is still pending
 * @param {string} resource
 */
const cancelPreviousRequest = (resource) => {
  if (cancelTokenMap[resource]) {
    cancelTokenMap[resource].cancel('Request cancelled due to new request.');
  }
  cancelTokenMap[resource] = axios.CancelToken.source();
};

/** @param {string} resource */
export const getAll = (resource, model = '') => {

  cancelPreviousRequest(resource);
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}${model}`;

  return axios
    .get(url, { headers, cancelToken: cancelTokenMap[resource].token })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const get = (resource, id) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}${id}`;

  return axios
    .get(`${BASE_URL}${resource}${id}`, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const post = (resource, model) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}`;
  const isFormData = model instanceof FormData;

  if (isFormData) {
    delete headers['Content-Type'];
  }

  return axios
    .post(url, model, { headers: headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const put = (resource, model) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}`;

  return axios
    .put(url, model, { headers: headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const deleteAPI = (id) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${id}`;

  return axios
    .delete(url, { headers: headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};
