export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_URL = process.env.REACT_APP_BASE_URL.replace("api", "storage");
export const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";
export const REGISTER = "auth/register";
export const FORGOT_PASSWORD = "auth/forgot-password";
export const RESET_PASSWORD = "auth/reset-password";
export const GET_PROFILE = "profile";
export const CHANGE_PASSWORD = "profile/change-password";
export const MASTER_DATA = "master-data";
export const SYSTEM_DATA = "system-data";
export const GET_MEDICINE_LIST = "medicine";
export const GET_MEDICINE_TYPE_LIST = "medicine/type";
export const GET_SERVICE_TYPE_LIST = "service/type";
export const GET_DEPARTMENT_LIST = "departments";
export const GET_HOSPITAL_LIST = "hospitals";
export const GET_SUPPLIER_LIST = "suppliers";
export const GET_SERVICE_LIST = "services";
export const GET_MEDS_LIST = "medicine";
export const ADD_MEDICINE = "medicine/create";
export const ADD_MEDICINE_TYPE = "medicine/type/create";
export const ADD_SERVICE_TYPE = "service/type/create";
export const ADD_DEPARTMENT = "departments/upsert";
export const ADD_HOSPITAL = "hospitals/upsert";
export const ADD_SUPPLIER = "suppliers/upsert";
export const ADD_MEDS = "medicine/type/create";
export const ADD_SERVICE = "services/create";
export const GET_REPORTS = "reports";
export const GET_REPORT_TYPES = "reports/type-list";
export const EDIT_MEDICINE = "medicine/edit/";
export const EDIT_SERVICE = "services/edit/";
export const EDIT_SERVICE_TYPE = "service/type/edit/";
export const EDIT_MEDICINE_TYPE = "medicine/type/edit/";
export const EDIT_DEPARTMENT = "departments/upsert";
export const EDIT_HOSPITAL = "hospitals/upsert";
export const EDIT_SUPPLIER = "suppliers/upsert";
export const CHANGE_SUPPLIER_STATUS = "suppliers/status/";
export const CHANGE_HOSPITAL_STATUS = "hospitals/status/";
export const CHANGE_DEPARTMENT_STATUS = "departments/status/";
export const CHANGE_SERVICE_STATUS = "services/status/";
export const CHANGE_SERVICE_TYPE_STATUS = "service/type/status/";
export const CHANGE_MEDICINE_STATUS = "medicine/status/";
export const CHANGE_MEDICINE_TYPE_STATUS = "medicine/type/status/";
export const DELETE_MEDICINE = "medicine/";
export const GET_PURCHASE_MEDICINE_LIST = "medicine/purchase";
export const IMPORT_MASTER = "import/list/";
export const EXPORT_MASTER = "import/";
export const ADD_PURCHASE_MEDICINE = "medicine/purchase/";
export const EDIT_PURCHASE_MEDICINE = "medicine/purchase/update/";
export const MEDICINE_RETURN = "medicine/purchase-return";
export const DELETE_PURCHASE_MEDICINE = "medicine/purchase/delete/";
export const SEARCH_PATIENT = "patient";
export const ADD_PATIENT_VISIT = "patient/create";
export const GET_PATIENTS_LIST = "patient/list";
export const GET_PATIENT_DETAILS_WITH_VISITS = "patient/";
export const UPDATE_PATIENT_DETAILS = "patient/update/";
export const ADD_PRESCRIPTION = "patient/prescription/add/";
export const GET_PRESCRIPTION = "patient/prescription/";
export const GET_MEDICINE_ORDER = "patient/order/";
export const ADD_DAMS = "patient/dams/create";
export const PATIENT_PURCHASE_MEDICINE = "patient/medicine/purchase/";
export const SALE_RETURN_LIST = "patient/order/return/list";
export const ADD_SALE_RETURN = "patient/order/return";
export const GET_USERS_LIST = "users";
export const UPSERT_USERS = "users/upsert";
export const CHANGE_USER_STATUS = "users/status/";
export const GET_DASHBOARD_DATA = "dashboard";
export const GET_DASHBOARD_GRAPHS = "dashboard/graph";
export const GET_SUPER_ADMIN_DASHBOARD_DATA = "dashboard/hospital-count";
export const MEDICINE_SALE_HISTORY = "medicine/history/";
export const SERVICE_SALE_HISTORY = "services/sale-history/";

export const EXPORT_DATA_REQ = "invoice/pdf";
export const DELETE_EXPORT_DATA = "invoice/pdf/delete/";
export const EXPORT_DATA_LIST = "invoice/pdf-list";

export const INVOICE_ORDER_PDF = "invoice/pdf/order/";

export const PATIENT_INVESTIGATION_DETAILS = "investigation/patient";

export const HOSPITAL_DOCTORS = "hospitals/doctor/";

export const ATTENDANCE = "staff-attendance";
