// response.js
import * as commonServices from './common';
import * as toast from "../Wrapper/toast/toast";
import axios from 'axios';
import { SUCCESS_CODE, UNAUTHORIZED_ERROR_CODE, RESOURCE_CREATED, ERROR_CODE } from '../Constants/responseCodes';
import { trackAPITime } from '../helpers/analytics';

export function handleResponse(resp, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, SUCCESS_CODE);

    let response = resp ?? {};

    if (response?.data) {
        if (response?.data?.code === SUCCESS_CODE || response?.data?.code === RESOURCE_CREATED) {
            response.data.isSuccess = true;
            return response.data;
        } else if (response?.data?.code === UNAUTHORIZED_ERROR_CODE) {
            commonServices.clearLocalStorageData();
            window.location.href = '/';
            toast.warn(response?.data?.message);
            return;
        } else {
            return { isSuccess: false, message: "Something went wrong!" };
        }
    } else {
        return { isSuccess: false, message: "Something went wrong!" };
    }
};

export function handleError(err, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, "ERROR");

    let error = err ?? {};

    if (axios.isCancel(error)) {
        console.log('Request cancelled:', error?.message);
        return;
    }

    let { response } = error;

    if (response?.data) {
        if (response?.data?.code === UNAUTHORIZED_ERROR_CODE) {
            commonServices.clearLocalStorageData();
            window.location.href = '/';
            toast.warn(response?.data?.message);
            return;
        } else if (response?.data?.code === ERROR_CODE) {
            response.data.isSuccess = false;
            return response.data;
        } else {
            return { isSuccess: false, message: "Something went wrong!" };
        }
    } else {
        return { isSuccess: false, message: "Something went wrong!" };
    }
};
